<template>
  <header id="header">
    <ul class="social list-unstyled">
      <li class="icon"><a class="twitter" href="https://twitter.com/TeddyCatsCasino" target="_blank"></a></li>
      <li class="icon"><a class="discord" href="https://discord.gg/MZVAW8PkG6" target="_blank"></a></li>
    </ul>
    <div class="header-center">
      <div id="logo" class="logo">
        <center>
        <a href="/"><img src="img/kelepar-logo.png" style="width: 65px; height: 90px;"></a>
        </center>
      </div>
      <nav id="mobileToggle" class="dropdown-content">
        <ul class="list-unstyled row vertical-center horizontal-center">
          <li class="mobile-only" v-if="!accounts">
            <a href="#">Connect Wallet</a>
          </li>
          <li>
            <a @click="changeDisplayState(COMING_SOON)">Roadmap</a>
          </li>
          <li>
            <a href="https://casino.teddycats.io">Casino</a>
          </li>
          <li>
            <a @click="changeDisplayState(COMING_SOON)">About Us</a>
          </li>
          <li v-if="accounts">
            <a @click="changeDisplayState(COMING_SOON)">Mint</a>
          </li>
          <li class="mobile-only" v-if="accounts">
            <a @click="disconnectWallet();"><span><img style="max-width: 20px" src="img/logout.png"
                                                       alt=""></span></a>
          </li>
          <li class="mobile-only" v-if="accounts">
            <a @click="changeDisplayState(COMING_SOON)"><span><img style="max-width: 20px" src="img/user.png"
                                                                   alt=""></span></a>
          </li>
          <li class="mobile-only" v-if="accounts">
            <div v-if="accounts.length">
              <p v-if="accounts[0].address" class="wallet-info-p desert-list-font">
                {{ getShortAddress(accounts[0].address) }}</p>
              <p v-if="accounts[0].balance" class="wallet-info-p desert-list-font">{{
                  parseInt(accounts[0].balance.amount) / 1000000
                }} {{ chainMeta.currencies[0].coinDenom }}</p>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="connect" v-if="!accounts">
      <a id="connect" href="https://casino.teddycats.io" class="btn-center btn-primary btn-smaller">
        <span class="btn-line">Play</span>
      </a>
    </div>
    <div class="view-owner" v-if="accounts">
      <div v-if="accounts.length">
        <a @click="changeDisplayState(COMING_SOON);" id="view-owner" class="btn-icon">
          <span class="btn-line"><img style="max-width: 20px" src="img/user.png" alt=""></span>
        </a>
      </div>
    </div>
    <div class="disconnect" v-if="accounts">
      <div v-if="accounts.length">
        <a @click="disconnectWallet();" id="disconnect" class="btn-icon">
          <span class="btn-line"><img style="max-width: 20px" src="img/logout.png" alt=""></span>
        </a>
      </div>
    </div>
    <div class="wallet-info" v-if="accounts">
      <div v-if="accounts.length">
        <p v-if="accounts[0].address" class="wallet-info-p desert-list-font" id="wallet-info-name">
          {{ getShortAddress(accounts[0].address) }}</p>
        <p v-if="accounts[0].balance" class="wallet-info-p desert-list-font" id="wallet-info-balance">{{
            parseInt(accounts[0].balance.amount) / 1000000
          }} {{ chainMeta.currencies[0].coinDenom }}</p>
      </div>
    </div>

    <div class="mobile-logo">
      <a href="/"><img src="img/kelepar-logo.png"></a>
    </div>
    <div class="mobile-nav">
      <img src="img/menu.svg" class="toggle-menu" onclick="myFunction()">
    </div>
  </header>

  <div class="startsite" v-if="currentState === COMING_SOON">
    <div class="hero text-center">
      <div class="hero-content">
        <h3 class="desert-dark-font">Casino</h3>
        <h2 class="desert-font" style="margin-bottom: 60px;">
          COMING SOON!</h2>
      </div>
    </div>
  </div>

  <div class="startsite" v-if="!accounts && currentState === DISCONNECTED">
    <div class="hero text-center">
      <div class="hero-content">
        <h3 class="desert-dark-font">Marketplace</h3>
        <h2 id="wallet_error" ref="wallet_error" class="desert-font desktop-only" style="margin-bottom: 60px;">
          Connect your wallet to enter the marketplace</h2>
        <h2 class="desert-font mobile-only" id="wallet_error_mobile" ref="wallet_error_mobile"
            style="margin-bottom: 60px;">Marketplace</h2>
        <a @click="connectWallet();" id="wallet_error_button" ref="wallet_error_button"
           class="btn-center btn-primary btn-lg">
                <span class="btn-line" id="wallet_error_button_text" ref="wallet_error_button_text">
                    Connect Wallet
                </span>
        </a>
      </div>
    </div>
  </div>
  <!-- Connected -->
  <div class="content">
    <div v-if="currentState === VIEW_OWNER">
      <!--      <div class="text-center">-->
      <!--        <div class="hero text-center" v-if="accounts">-->
      <!--          <div class="hero-content">-->
      <!--            <h3 class="desert-dark-font">Wallet connected!</h3>-->
      <!--      <div v-if="accounts.length">-->
      <!--        <ul class="">-->
      <!--          <li v-for="(account, i) in accounts" :key="i">-->
      <!--            &lt;!&ndash; Address &ndash;&gt;-->
      <!--            <h2 class="desert-font desktop-only" style="font-size: 30px;  margin: 20px 0 !important;">Account:-->
      <!--              {{ account.address }}</h2>-->
      <!--            <p class="desert-font mobile-only" style="font-size: 12px;">Account: {{ account.address }}</p>-->
      <!--            &lt;!&ndash; Balance &ndash;&gt;-->
      <!--            <div v-if="account.balance">-->
      <!--              <h2 class="desert-font desktop-only" style="font-size: 30px; margin: 20px 0 !important;"-->
      <!--                  v-if="!isNaN(account.balance.amount)">Balance: {{-->
      <!--                  parseInt(account.balance.amount) / 1000000-->
      <!--                }} {{ chainMeta.currencies[0].coinDenom }}</h2>-->
      <!--              <p class="desert-font mobile-only" style="font-size: 12px;" v-if="!isNaN(account.balance.amount)">-->
      <!--                Balance: {{-->
      <!--                  parseInt(account.balance.amount) / 1000000-->
      <!--                }} {{ chainMeta.currencies[0].coinDenom }}</p>-->
      <!--            </div>-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="kelepar-details padding" v-if="accounts && myNfts.length === 0">
        <div class="content width-lg">
          <div class="row pt">
            <div>
              <p class="desert-font">This wallet contains no NFTs.<br/>Check offers or auctions to buy some!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="kelepar-details padding-tiles text-center" v-if="accounts && myNfts.length > 0">
        <div class="content width-lg">
          <h2 class="kelepar-heading text-center mb"><span>My NFTs</span></h2>
          <div class="row pt" v-for="(nfts,i) in chunkedNfts" :key="i">
            <div v-for="(token,i) in nfts" :key="i" class="col">
              <h3 v-if="token.name">{{ token.name }}</h3>
              <div class="nft-max-width game-icon detail-img text-center">
                <img :src="token.image" v-if="token.image" alt="token.name"/>
              </div>
              <p class="desert-font" v-if="token.description">
                {{ token.description }}
              </p>
              <div class="id" v-if="token.id">
                <p class="desert-font"><strong>Token ID:</strong> {{ token.id }}</p>
              </div>
              <div class="owner" v-if="token.owner">
                <p class="desert-font">
                  <strong>Owned by:</strong>&nbsp;
                  <span v-if="token.owner !== accounts[0].address">{{ getShortAddress(token.owner) }}</span>
                  <span v-if="token.owner == accounts[0].address">You</span>
                </p>
              </div>
              <div class="vertical-center">
                <div v-if="token.offerData">
                  <a @click="handleWithdrawOffer(token.offerData.id);"
                     class="btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Withdraw offer
                </span>
                  </a>
                </div>
                <div v-if="token.auctionData">
                  <a @click="handleWithdrawAuction(token.auctionData.id);"
                     class="btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Withdraw auction
                </span>
                  </a>
                </div>
                <div v-if="token.invAuctionData">
                  <a @click="handleWithdrawInvertedAuction(token.invAuctionData.id);"
                     class="btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Withdraw Inv auction
                </span>
                  </a>
                </div>
                <div v-if="!token.offerData && !token.auctionData && !token.invAuctionData">
                  <div class="controls transfer-controls">
                    <a v-if="selectedTokenId !== token.id && !transferring.selected && !createOffer.selected  && !createAuction.selected && !createInvAuction.selected"
                       @click="selectedTokenId = token.id; transferring.selected = true"
                       class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Transfer
                </span>
                    </a>
                    <a v-if="selectedTokenId === token.id && transferring.selected"
                       @click="selectedTokenId = null; ; transferring.selected = false"
                       class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Cancel
                </span>
                    </a>
                    <!-- Transfer NFT -->
                    <div :class="'transfer transfer-'+i"
                         v-if="selectedTokenId === token.id && transferring.selected === true">
                      <!-- Token: Receiving Address -->
                      <label class="recipient" :for="'nft_transfer_'+i"><strong>Recipient:</strong></label>
                      <input v-model="transferring.recipient" :name="'nft_transfer_'+i" class="form-control"
                             type="text"
                             placeholder="archway1...">
                      <a style="margin-top: 20px" @click="handleTransfer();"
                         :class="{ 'btn-disabled' : !transferring.recipient || !selectedTokenId || isSending }"
                         class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Send
                </span>
                      </a>
                    </div>
                  </div>
                  <div class="controls offer-controls">
                    <a v-if="selectedTokenId !== token.id && !transferring.selected && !createOffer.selected  && !createAuction.selected && !createInvAuction.selected"
                       @click="selectedTokenId = token.id; createOffer.selected = true"
                       class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Create Offer
                </span>
                    </a>
                    <a v-if="selectedTokenId === token.id && createOffer.selected"
                       @click="selectedTokenId = null; createOffer.selected = false"
                       class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Cancel
                </span>
                    </a>
                    <!-- Create Offer -->
                    <div :class="'offer offer-'+i"
                         v-if="selectedTokenId === token.id && createOffer.selected === true">
                      <!-- Token: Receiving Address -->
                      <label class="price" :for="'nft_offer_'+i"><strong>Price:</strong></label>
                      <input v-model="createOffer.price" :name="'nft_offer_'+i" class="form-control" type="number"
                             placeholder="1">
                      <a style="margin-top: 20px" @click="handleOfferNft();"
                         :class="{ 'btn-disabled' : !createOffer.price || !selectedTokenId || isCreatingOffer }"
                         class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Create Offer
                </span>
                      </a>
                    </div>
                  </div>
                  <div class="controls auction-controls">
                    <a v-if="selectedTokenId !== token.id && !transferring.selected && !createOffer.selected  && !createAuction.selected && !createInvAuction.selected"
                       @click="selectedTokenId = token.id; createAuction.selected = true"
                       class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Create Auction
                </span>
                    </a>
                    <a v-if="selectedTokenId === token.id && createAuction.selected"
                       @click="selectedTokenId = null; createAuction.selected = false"
                       class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Cancel
                </span>
                    </a>
                    <!-- Create Auction -->
                    <div :class="'auction auction-'+i"
                         v-if="selectedTokenId === token.id && createAuction.selected === true">
                      <!-- Token: Receiving Address -->
                      <label class="min_bid" :for="'nft_auction_'+i"><strong>Min bid:</strong></label>
                      <input v-model="createAuction.min_bid" :name="'nft_auction_'+i" class="form-control"
                             type="number"
                             placeholder="1">
                      <br/>
                      <label class="max_bid" :for="'nft_auction_'+i"><strong>Max bid (optional):</strong></label>
                      <input v-model="createAuction.max_bid" :name="'nft_auction_'+i" class="form-control"
                             type="number"
                             placeholder="2">
                      <br/>
                      <label class="start_time" :for="'nft_auction_'+i"><strong>Start time:</strong></label>
                      <input v-model="createAuction.start_time" :name="'nft_auction_'+i" class="form-control"
                             type="datetime-local">
                      <br/>
                      <label class="deadline" :for="'nft_auction_'+i"><strong>Deadline:</strong></label>
                      <input v-model="createAuction.deadline" :name="'nft_auction_'+i" class="form-control"
                             type="datetime-local">
                      <a style="margin-top: 20px" @click="handleAuctionNft();"
                         :class="{ 'btn-disabled' : !createAuction.min_bid || !createAuction.start_time || !createAuction.deadline || !selectedTokenId || isCreatingAuction }"
                         class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Create Auction
                </span>
                      </a>
                    </div>
                  </div>
                  <div class="controls invauction-controls">
                    <a v-if="selectedTokenId !== token.id && !transferring.selected && !createOffer.selected  && !createAuction.selected && !createInvAuction.selected"
                       @click="selectedTokenId = token.id; createInvAuction.selected = true"
                       class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Create Inv Auction
                </span>
                    </a>
                    <a v-if="selectedTokenId === token.id && createInvAuction.selected"
                       @click="selectedTokenId = null; createInvAuction.selected = false"
                       class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Cancel
                </span>
                    </a>
                    <!-- Create Inverted Auction -->
                    <div :class="'invauction invauction-'+i"
                         v-if="selectedTokenId === token.id && createInvAuction.selected === true">
                      <!-- Token: Receiving Address -->
                      <label class="start_price" :for="'nft_invauction_'+i"><strong>Start price:</strong></label>
                      <input v-model="createInvAuction.start_amount" :name="'nft_auction_'+i" class="form-control"
                             type="number"
                             placeholder="2">
                      <br/>
                      <label class="reduce_amount" :for="'nft_invauction_'+i"><strong>Reduce
                        amount:</strong></label>
                      <input v-model="createInvAuction.reduce_amount" :name="'nft_auction_'+i"
                             class="form-control"
                             type="number"
                             placeholder="0.5">
                      <br/>
                      <label class="end_price" :for="'nft_invauction_'+i"><strong>End price:</strong></label>
                      <input v-model="createInvAuction.end_amount" :name="'nft_auction_'+i" class="form-control"
                             type="number"
                             placeholder="1">
                      <br/>
                      <label class="start_time" :for="'nft_invauction_'+i"><strong>Start time:</strong></label>
                      <input v-model="createInvAuction.start_time" :name="'nft_auction_'+i" class="form-control"
                             type="datetime-local">
                      <br/>
                      <label class="deadline" :for="'nft_invauction_'+i"><strong>Deadline:</strong></label>
                      <input v-model="createInvAuction.deadline" :name="'nft_auction_'+i" class="form-control"
                             type="datetime-local">
                      <a style="margin-top: 20px" @click="handleInvAuctionNft();"
                         :class="{ 'btn-disabled' : !createInvAuction.price || !selectedTokenId || isCreatingInvAuction }"
                         class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Create Inv Auction
                </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- STATES: Wallet Information -->
    <!--    <div class="nfts mine" v-if="currentState == VIEW_OWNER && !selectedOwner">-->
    <!--      <div class="kelepar-details padding">-->
    <!--        <div class="content width-lg">-->
    <!--          <h2 class="kelepar-heading text-center mb"><span>My NFTs</span></h2>-->
    <!--          <div v-if="nfts.market">-->
    <!--            <div v-if="!nfts.market.tokens.length">-->
    <!--              <p>You own no NFTs</p>-->
    <!--            </div>-->
    <!--            <div class="row pt">-->
    <!--              <div v-if="nfts.market.tokens.length">-->
    <!--                <div v-if="myNfts.length">-->
    <!--                  <div v-for="(token,i) in myNfts" :key="i" class="col">-->
    <!--                    <h3 v-if="token.name"><span>{{ token.name }}</span></h3>-->
    <!--                    <div class="game-icon detail-img text-center">-->
    <!--                      <img :src="token.image" v-if="token.image" alt="token.name"/>-->
    <!--                    </div>-->
    <!--                    <p v-if="token.description">-->
    <!--                      {{ token.description }}-->
    <!--                    </p>-->
    <!--                    <div class="id" v-if="token.id">-->
    <!--                      <p><strong>Token ID:</strong> {{ token.id }}</p>-->
    <!--                    </div>-->
    <!--                    <div class="owner" v-if="token.owner">-->
    <!--                      <p>-->
    <!--                        <strong>Owned by:</strong>&nbsp;<span>You</span>-->
    <!--                      </p>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->


    <!--      <div v-if="nfts.market.tokens.length">-->
    <!--        <div class="card-deck" v-if="myNfts.length">-->
    <!--          <div class="card" v-for="(token,i) in myNfts" :key="i">-->
    <!--            <img class="card-img-top" :src="token.image" v-if="token.image">-->
    <!--            <div class="card-body">-->
    <!--              <h5 class="card-title" v-if="token.name">{{ token.name }}</h5>-->
    <!--              <p class="card-text" v-if="token.description">{{ token.description }}</p>-->
    <!--              <div class="id" v-if="token.id">-->
    <!--                <p><strong>Token ID:</strong> {{ token.id }}</p>-->
    <!--              </div>-->
    <!--              <div class="owner" v-if="token.owner">-->
    <!--                <p>-->
    <!--                  <strong>Owned by:</strong>&nbsp;<span>You</span>-->
    <!--                </p>-->
    <!--              </div>-->


    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--    STATES: View NFTs of Owner-->
    <!--    <div class="nfts address" v-if="currentState == VIEW_OWNER && selectedOwner">-->
    <!--      <h3>{{ selectedOwner }}'s NFTs</h3>-->
    <!--    </div>-->

    <!-- STATES: Offers -->
    <div class="offers" v-if="currentState == OFFERS">
      <div class="kelepar-details padding-tiles text-center" v-if="offeredNfts.length === 0 && !loading.status">
        <div class="content width-lg">
          <h2 class="desert-font text-center mb"><span>Offers</span></h2>
          <h2 class="desert-dark-font text-center mb"><span>There are currently no offers.</span></h2>
        </div>
      </div>
      <!-- Offers -->
      <div class="kelepar-details padding-tiles text-center" v-if="chunkedOfferedNfts">
        <div class="content width-lg" v-if="chunkedOfferedNfts.length > 0">
          <h2 class="desert-font text-center mb"><span>Offers</span></h2>
          <div class="row pt" v-for="(offeredNfts,i) in chunkedOfferedNfts" :key="i">
            <div v-for="(offeredNft,i) in offeredNfts" :key="i" class="col">
              <h3 v-if="offeredNft.name">{{ offeredNft.name }}</h3>
              <div class="nft-max-width game-icon detail-img text-center">
                <img :src="offeredNft.image" v-if="offeredNft.image" alt="token.name"/>
              </div>
              <p class="desert-font" v-if="offeredNft.description">
                {{ offeredNft.description }}
              </p>
              <div class="id" v-if="offeredNft.id">
                <p class="desert-font"><strong>Token ID:</strong> {{ offeredNft.id }}</p>
              </div>
              <div class="owner" v-if="offeredNft.owner">
                <p v-if="accounts" class="desert-font">
                  <strong>Offer by:</strong>&nbsp;
                  <span v-if="offeredNft.owner !== accounts[0].address">{{ getShortAddress(offeredNft.owner) }}</span>
                  <span v-if="offeredNft.owner === accounts[0].address">You</span>
                </p>
                <p v-if="!accounts" class="desert-font">
                  <strong>Offer by:</strong>&nbsp;
                  <span>{{ getShortAddress(offeredNft.owner) }}</span>
                </p>
              </div>
              <div class="price" v-if="offeredNft.offerData.list_price">
                <p class="desert-font">
                  <strong>Price:</strong>&nbsp;
                  <span>{{ parseInt(offeredNft.offerData.list_price.amount) / 1000000 }}</span>
                </p>
              </div>
              <div v-if="accounts" class="controls transfer-controls">
                <a @click="handleAcceptOffer(offeredNft.offerData.id, offeredNft.offerData.list_price.amount);"
                   class="btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Acccept offer
                </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- STATES: Auctions -->
    <div class="auctions" v-if="currentState == AUCTIONS">
      <div class="kelepar-details padding-tiles text-center" v-if="auctionedNfts.length === 0">
        <div class="content width-lg">
          <h2 class="desert-font text-center mb"><span>Auctions</span></h2>
          <h2 class="desert-dark-font text-center mb"><span>There are currently no auctions.</span></h2>
        </div>
      </div>
      <!-- Auctions -->
      <div class="kelepar-details padding-tiles text-center" v-if="auctionedNfts.length > 0">
        <div class="content width-lg">
          <h2 class="desert-font text-center mb"><span>Auctions</span></h2>
          <div class="row pt" v-for="auctionedNfts in chunkedAuctionedNfts" :key="auctionedNfts.id">
            <div v-for="auctionedNft in auctionedNfts" :key="auctionedNft.id" class="col">
              <h3 v-if="auctionedNft.name">{{ auctionedNft.name }}</h3>
              <div class="nft-max-width game-icon detail-img text-center">
                <img :src="auctionedNft.image" v-if="auctionedNft.image" alt="token.name"/>
              </div>
              <p class="desert-font" v-if="auctionedNft.description">
                {{ auctionedNft.description }}
              </p>
              <div class="id" v-if="auctionedNft.id">
                <p class="desert-font"><strong>Token ID:</strong> {{ auctionedNft.id }}</p>
              </div>
              <div class="owner" v-if="auctionedNft.owner">
                <p v-if="accounts" class="desert-font">
                  <strong>Auction by:</strong>&nbsp;
                  <span v-if="auctionedNft.owner !== accounts[0].address">{{
                      getShortAddress(auctionedNft.owner)
                    }}</span>
                  <span v-if="auctionedNft.owner === accounts[0].address">You</span>
                </p>
                <p v-if="!accounts" class="desert-font">
                  <strong>Auction by:</strong>&nbsp;
                  <span>{{ getShortAddress(auctionedNft.owner) }}</span>
                </p>
              </div>
              <div class="min_bid" v-if="auctionedNft.auctionData.min_bid">
                <p class="desert-font">
                  <strong>Min Bid:</strong>&nbsp;
                  <span>{{ parseInt(auctionedNft.auctionData.min_bid) / 1000000 }}</span>
                </p>
              </div>
              <div class="max_bid" v-if="auctionedNft.auctionData.max_bid">
                <p class="desert-font">
                  <strong>Max Bid:</strong>&nbsp;
                  <span>{{ parseInt(auctionedNft.auctionData.max_bid) / 1000000 }}</span>
                </p>
              </div>
              <div class="current_bid" v-if="auctionedNft.auctionData.current_bid">
                <p class="desert-font">
                  <strong>Current Bid:</strong>&nbsp;
                  <span v-if="(parseInt(auctionedNft.auctionData.current_bid.amount)) > 0">{{
                      parseInt(auctionedNft.auctionData.current_bid.amount) / 1000000
                    }}</span>
                  <span v-else>None</span>
                </p>
              </div>
              <div class="controls auction-controls" v-if="accounts">
                <a v-if="selectedAuctionTokenId !== auctionedNft.id && !bidOnAnAuction.selected"
                   @click="selectedAuctionTokenId = auctionedNft.id; bidOnAnAuction.auction_id = auctionedNft.auctionData.id; bidOnAnAuction.selected = true"
                   class="btn-nft btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Bid
                </span>
                </a>
                <a v-if="selectedAuctionTokenId === auctionedNft.id && bidOnAnAuction.selected"
                   @click="selectedAuctionTokenId = null; bidOnAnAuction.auction_id = null; bidOnAnAuction.selected = false"
                   class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Cancel
                </span>
                </a>
                <!-- Bid on Auction -->
                <div :class="'transfer transfer-'+i"
                     v-if="selectedAuctionTokenId === auctionedNft.id && bidOnAnAuction.selected === true">
                  <!-- Bid Amount -->
                  <label class="recipient" :for="'nft_transfer_'+i"><strong>Amount:</strong></label>
                  <input v-model="bidOnAnAuction.bid_amount" :name="'nft_transfer_'+i" class="form-control"
                         type="number"
                         placeholder="0.5">
                  <a style="margin-top: 20px" @click="handleBidOnAuction();"
                     :class="{ 'btn-disabled' : !bidOnAnAuction.bid_amount || !bidOnAnAuction.auction_id || !selectedAuctionTokenId || isBiddingOnAuction }"
                     class="btn-center btn-primary btn-danger btn-cancel btn-lg">
                <span class="btn-line">
                    Bid on auction
                </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- STATES: Inverted Auctions -->
    <div class="invauctions" v-if="currentState == INVAUCTIONS">
      <div class="kelepar-details padding-tiles text-center" v-if="invAuctionedNfts.length === 0">
        <div class="content width-lg">
          <h2 class="desert-font text-center mb"><span>Inverted Auctions</span></h2>
          <h2 class="desert-dark-font text-center mb"><span>There are currently no inverted auctions.</span></h2>
        </div>
      </div>
      <!-- Auctions -->
      <div class="kelepar-details padding-tiles text-center" v-if="invAuctionedNfts.length > 0">
        <div class="content width-lg">
          <h2 class="desert-font text-center mb"><span>Inverted Auctions</span></h2>
          <div class="row pt" v-for="(invAuctionedNfts,i) in chunkedInvAuctionedNfts" :key="i">
            <div v-for="(invAuctionedNft,i) in invAuctionedNfts" :key="i" class="col">
              <h3 v-if="invAuctionedNft.name">{{ invAuctionedNft.name }}</h3>
              <div class="nft-max-width game-icon detail-img text-center">
                <img :src="invAuctionedNft.image" v-if="invAuctionedNft.image" alt="token.name"/>
              </div>
              <p class="desert-font" v-if="invAuctionedNft.description">
                {{ invAuctionedNft.description }}
              </p>
              <div class="id" v-if="invAuctionedNft.id">
                <p class="desert-font"><strong>Token ID:</strong> {{ invAuctionedNft.id }}</p>
              </div>
              <div class="owner" v-if="invAuctionedNft.owner">
                <p v-if="accounts" class="desert-font">
                  <strong>Auction by:</strong>&nbsp;
                  <span v-if="invAuctionedNft.owner !== accounts[0].address">{{
                      getShortAddress(invAuctionedNft.owner)
                    }}</span>
                  <span v-if="invAuctionedNft.owner === accounts[0].address">You</span>
                </p>
                <p v-if="!accounts" class="desert-font">
                  <strong>Auction by:</strong>&nbsp;
                  <span>{{ getShortAddress(invAuctionedNft.owner) }}</span>
                </p>
              </div>
              <div class="min_bid" v-if="invAuctionedNft.auctionData.min_bid">
                <p class="desert-font">
                  <strong>Min Bid:</strong>&nbsp;
                  <span>{{ invAuctionedNft.auctionData.min_bid }}</span>
                </p>
              </div>
              <div class="max_bid" v-if="invAuctionedNft.auctionData.max_bid">
                <p class="desert-font">
                  <strong>Max Bid:</strong>&nbsp;
                  <span>{{ invAuctionedNft.auctionData.max_bid }}</span>
                </p>
              </div>
              <div class="current_bid" v-if="invAuctionedNft.auctionData.current_bid">
                <p class="desert-font">
                  <strong>Current Bid:</strong>&nbsp;
                  <span>{{ invAuctionedNft.auctionData.current_bid.amount }}</span>
                </p>
              </div>
              <div v-if="accounts" class="controls transfer-controls">
                <a @click="handleBidOnAuction(invAuctionedNft.id);"
                   class="btn-center btn-primary btn-lg">
                <span class="btn-line">
                    Acccept offer
                </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- STATES: Mint -->
    <div class="offers" v-if="currentState == MINT">
      <div class="kelepar-details padding-tiles">
        <div class="content width-lg">
          <h2 class="kelepar-heading text-center mb"><span>Mint your own NFTs!</span></h2>
          <div class="minting-form">
            <div class="name">
              <label for="nft_name"><h2>Name:</h2></label>
              <input v-model="metadata.ipfsMetadata.name" name="nft_name" class="form-control" type="text"
                     placeholder="My NFT name">
            </div>
            <div class="description">
              <label for="nft_descr"><h2>Description:</h2></label>
              <textarea v-model="metadata.ipfsMetadata.description" name="nft_descr" class="form-control"></textarea>
            </div>

            <div class="image">
              <label><h2>Art:</h2></label>
              <div class="dropzone" :class="{ok: files.length, waiting: !files.length}" @dragover="dragover"
                   @dragleave="dragleave" @drop="drop">
                <input
                    type="file"
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="hidden"
                    @change="onChange"
                    ref="file"
                    accept="image/png, image/gif, image/jpeg"
                />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div v-if="!files.length">
                    <p class="instr-t">Drag and drop NFT art here</p>
                  </div>
                </label>

                <ul class="files-list-ul" v-cloak>
                  <li class="text-sm p-1" v-for="(file,i) in files" :key="i">
                    <p>{{ file.name }}</p>
                    <button class="btn btn-danger btn-reset" type="button" @click="clearFiles();" title="Remove file">
                      Reset
                    </button>
                  </li>
                </ul>
              </div>
              <span>*accepted file types: png, gif, jpeg</span>

              <div class="controls minting-controls">
                <a @click="ipfsUpload();" class="btn-center btn-primary btn-lg"
                   @click.prevent="!files.length || !metadata.ipfsMetadata.description || !metadata.ipfsMetadata.name || isMinting">
                <span class="btn-line">
                    Mint NFT
                </span>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>

    <div class="controls logs" v-if="logs.length && !showLogs">
      <button class="btn btn-inverse" @click="showLogs = true;">Show Logs</button>
    </div>

    <!--    <div class="logs" v-if="logs.length && showLogs">-->
    <!--      <div class="controls">-->
    <!--        <button class="btn btn-inverse" @click="showLogs = false;">Hide Logs</button>-->
    <!--      </div>-->
    <!--      <div v-for="(log,i) in logs" :key="i">-->
    <!--        <p class="label" v-if="log.timestamp">-->
    <!--          <strong>-->
    <!--            <span v-if="log.mint">Minted NFT&nbsp;</span>-->
    <!--            <span v-if="log.transfer">Transferred NFT:&nbsp;</span>({{ log.timestamp }}):-->
    <!--          </strong>-->
    <!--        </p>-->
    <!--        <pre class="log-entry">{{ log }}</pre>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
import {SigningCosmWasmClient} from "@cosmjs/cosmwasm-stargate";
import {calculateFee} from "@cosmjs/stargate";
import axios from 'axios';
import ipfsClient from './ipfs';
import {onMounted} from "vue";
import AOS from "aos";
import chunk from "chunk";
import moment from "moment";
import {ConstantineInfo} from "@/chain.info.constantine";

// You can either import the default styles or create your own.

// You can either import the default styles or create your own.

const RPC = ConstantineInfo.rpc;
const MPContractAddress = "archway1t9lgm3eczs5ewd74lsg7txkp2yglhgqpg9snfcfy47cseej793kqcdnty8";
const MintContractAddress = "archway1fz4m74vdvy06cwn8y5fwycasztfgrchja7h0dg0uu7gwtwsm0ajsw9adkv";

const IPFS_PREFIX = 'ipfs://';
const IPFS_SUFFIX = '/';

const POSSIBLE_STATES = ['offer', 'auction', 'invauction', 'mint', 'token', 'owner', 'disconnected', 'view_nft', 'coming_soon'];
const COMING_SOON = 9;
const VIEW_OWNER = 6;
const DISCONNECTED = 7;
const VIEW_NFT = 8;
const OFFERS = 1;
const AUCTIONS = 2;
const INVAUCTIONS = 3;
const MINT = 4;
const VIEW_TOKEN = 5;

onMounted(() => {
  AOS.init();
})

export default {
  name: "App",
  data: () => ({
    VIEW_OWNER: VIEW_OWNER,
    COMING_SOON: COMING_SOON,
    OFFERS: OFFERS,
    VIEW_NFT: VIEW_NFT,
    AUCTIONS: AUCTIONS,
    INVAUCTIONS: INVAUCTIONS,
    DISCONNECTED: DISCONNECTED,
    MINT: MINT,
    VIEW_TOKEN: VIEW_TOKEN,
    contract: MintContractAddress,
    mpcontract: MPContractAddress,
    counter: null,
    wasmClient: null,
    axios: axios,
    chainMeta: ConstantineInfo,
    offlineSigner: null,
    gas: {
      price: null
    },
    handlers: {
      query: null
    },
    isInitialLoading: false,
    loading: {
      status: false,
      msg: ""
    },
    logs: [],
    showLogs: true,
    rpc: RPC,
    ipfs: ipfsClient.IPFS,
    accounts: null,
    states: POSSIBLE_STATES,
    currentState: COMING_SOON,
    lastViewedState: null,
    selectedOwner: null,
    offersQuery: null,
    offersTokenIds: [],
    offers: [],
    auctionsQuery: null,
    auctionTokenIds: [],
    auctions: [],
    invAuctionsQuery: null,
    invAuctionTokenIds: [],
    invAuctions: [],
    isMinting: false,
    isSending: false,
    isCreatingOffer: false,
    isAcceptingOffer: false,
    isWithdrawingOffer: false,
    isCreatingAuction: false,
    isBiddingOnAuction: false,
    isWithdrawingAuction: false,
    isCreatingInvAuction: false,
    isWithdrawingInvAuction: false,
    nfts: {
      user: null,
      market: null,
      metadata: {}
    },
    metadata: {
      tokenId: null,
      uri: null,
      ipfsMetadata: {
        name: null,
        description: null,
        image: null,
        date: null
      }
    },
    selectedTokenId: null,
    selectedAuctionTokenId: null,
    selectedInvAuctionTokenId: null,
    transferring: {
      selected: false,
      recipient: null,
    },
    createOffer: {
      selected: false,
      price: null
    },
    createAuction: {
      selected: false,
      min_bid: null,
      max_bid: null,
      start_time: null,
      deadline: null,
    },
    bidOnAnAuction: {
      selected: false,
      auction_id: null,
      bid_amount: null,
    },
    createInvAuction: {
      selected: false,
      start_amount: null,
      reduce_amount: null,
      end_amount: null,
      start_time: null,
      deadline: null,
    },
    files: [],
    image: null
  }),
  mounted: async function () {
    await this.initMethod();
  },
  methods: {
    getShortAddress(address = null) {
      if (address.length > 8) {
        let firstPart = address.substr(0, 8);
        let lastPart = address.substr(address.length - 4);
        return firstPart + "..." + lastPart;
      }
      return address;
    },
    disconnectWallet: async function () {
      console.log('Disconnecting wallet...');
      this.accounts = null;
      this.lastViewedState = this.currentState;
      this.wasmClient.disconnect();
      await this.changeDisplayState(DISCONNECTED);
      console.log('Wallet disconnected');
    },
    connectWallet: async function () {
      console.log('Connecting wallet...');
    	await window.solana.connect();
      window.onload = window.solana.connect({ onlyIfTrusted: true });

      const publicKey = window.solana.publicKey;

      console.log(publicKey.toString());

      

      
    },
    changeDisplayState: async function (state = 0, account = null) {
      if (typeof state !== 'number') {
        return;
      } else if (state < 0 || state > (this.states.length)) {
        console.warn('An invalid state was selected. State must be an integer within range 0 and ' + (this.states.length), state);
        return;
      }

      this.selectedOwner = (account) ? account : null;
      this.currentState = state;

      switch (state) {
        case OFFERS: {
          this.loadOffers();
          break;
        }
        case AUCTIONS: {
          this.loadAuctions();
          break;
        }
        case INVAUCTIONS: {
          this.loadInvertedAuctions();
          break;
        }
        case MINT: {
          this.resetMetadataForm();
          break;
        }
        case VIEW_TOKEN: {
          break;
        }
        case VIEW_OWNER: {
          break;
        }
      }

    },
    resetMetadataForm: function () {
      this.metadata = {
        tokenId: null,
        uri: null,
        ipfsMetadata: {
          name: null,
          description: null,
          date: null
        }
      };
      this.files = [];
      this.image = null;
      this.isMinting = false;
    },
    onChange: function () {
      this.files = this.$refs.file.files;
      console.log('(onChange) Files:', this.files);
    },
    clearFiles: function () {
      this.files = [];
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('ok')) {
        event.currentTarget.classList.add('hovering');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('hovering');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    ipfsUpload: async function () {
      if (!this.files.length) {
        console.warn('Nothing to upload to IPFS');
        return;
      }

      this.loading = {
        status: true,
        msg: "Uploading art to IPFS..."
      };

      this.isMinting = true;

      // Art upload
      const reader = new FileReader();
      let file = this.files[0];
      reader.readAsDataURL(file);

      reader.onload = async (event) => {
        this.image = event.target.result;
        // console.log('reader.onload', {
        //   reader: reader,
        //   result: reader.result,
        //   image: this.image
        // });
        try {
          let uploadResult = await this.ipfs.upload(this.image);
          console.log('Successfully uploaded art', [uploadResult, String(uploadResult.cid)]);

          // Metadata upload (json)
          this.loading = {
            status: true,
            msg: "Uploading metadata to IPFS..."
          };
          this.metadata.ipfsMetadata.date = new Date().getTime();
          this.metadata.ipfsMetadata.image = IPFS_PREFIX + String(uploadResult.cid);
          +IPFS_SUFFIX;

          let json = JSON.stringify(this.metadata.ipfsMetadata);
          const blob = new Blob([json], {type: "application/json"});
          const jsonReader = new FileReader();
          jsonReader.readAsDataURL(blob);

          jsonReader.onload = async (event) => {
            let jsonUploadTarget = event.target.result;
            let metadataUploadResult = await this.ipfs.upload(jsonUploadTarget);
            console.log('Successfully uploaded JSON metadata to IPFS', [metadataUploadResult, String(metadataUploadResult.cid)]);
            this.metadata.uri = IPFS_PREFIX + String(metadataUploadResult.cid) + IPFS_SUFFIX;

            // Mint NFT
            await this.mintNft();
          }
        } catch (e) {
          console.error('Error uploading file to IPFS: ', e);
          this.loading.status = false;
          this.loading.msg = "";
          return;
        }
      };
      reader.onerror = (e) => {
        console.error('Error uploading file to IPFS: ', e);
        this.loading.status = false;
        this.loading.msg = "";
        return;
      };
    },
    loadNfts: async function () {
      // Load NFTs
      try {
        // All NFTs (of contract)
        this.nfts.market = await this.getNfts();
        // console.log('All NFTs', this.nfts.market);
        console.log('NFTs at contract ' + this.contract + ' have been loaded', this.nfts);

        // Iterate ID's and get token data
        await this.loadNftData();
      } catch (e) {
        console.error('Error loading NFTs', {
          nfts: this.nfts,
          user: this.accounts,
          error: e
        });
      }
    },
    loadOffers: async function () {
      // Load Offers
      try {
        // All Offers (of contract)
        this.offersQuery = await this.getOffers();
        console.log('All Offers Query', this.offersQuery);
        this.offers = [];
        this.offersTokenIds = [];
        this.offersQuery.offerings.forEach((off) => {
          this.offers.push(off);
          this.offersTokenIds.push(off.token_id);
        })

        // add offerData to token
        await this.loadOfferData();
      } catch (e) {
        console.error('Error loading Offers', {
          offers: this.offers,
          user: this.accounts,
          error: e
        });
      }
    },
    loadOfferData: async function () {
      if (!this.nfts.market) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.nfts.market.tokens) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.offers) {
        console.warn('No Offers; nothing to query', this.offers);
        return;
      }

      for (let i = 0; i < this.nfts.market.tokens.length; i++) {
        let id = this.nfts.market.tokens[i].id;
        // if offer for nft exists, add offer data to token
        if (this.offersTokenIds.includes(id)) {
          let offerData = this.offers.find(o => o.token_id === id);
          console.log("OFFER DATA: " + offerData)
          if (offerData) {
            this.nfts.market.tokens[i].offerData = offerData;
          }
        }
      }
    },
    loadAuctions: async function () {
      // Load Auctions
      try {
        // All Auctions (of contract)
        this.auctionsQuery = await this.getAuctions();
        console.log('All Auctions Query', this.auctionsQuery);
        this.auctions = [];
        this.auctionTokenIds = [];
        this.auctionsQuery.auctions.forEach((auc) => {
          console.log('AUCTION: ', auc);
          this.auctions.push(auc);
          this.auctionTokenIds.push(auc.token_id);
        })

        // add auctionData to token
        await this.loadAuctionData();
      } catch (e) {
        console.error('Error loading Auctions', {
          auctions: this.auctions,
          user: this.accounts,
          error: e
        });
      }
    },
    loadAuctionData: async function () {
      if (!this.nfts.market) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.nfts.market.tokens) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.auctions) {
        console.warn('No Auctions; nothing to query', this.auctions);
        return;
      }

      for (let i = 0; i < this.nfts.market.tokens.length; i++) {
        let id = this.nfts.market.tokens[i].id;
        // if offer for nft exists, add offer data to token
        if (this.auctionTokenIds.includes(id)) {
          let auctionData = this.auctions.find(a => a.token_id === id);
          this.nfts.market.tokens[i].auctionData = auctionData;
        }
      }
    },
    loadInvertedAuctions: async function () {
      // Load Inverted Auctions
      try {
        // All Inverted Auctions (of contract)
        this.invAuctionsQuery = await this.getInvertedAuctions();
        console.log('All Inverted Auctions Query', this.invAuctionsQuery);
        this.invAuctions = [];
        this.invAuctionTokenIds = [];
        this.invAuctionsQuery.inverted_auctions.forEach((auc) => {
          console.log('INVERTED AUCTION: ', auc);
          this.invAuctions.push(auc);
          this.invAuctionTokenIds.push(auc.token_id);
        })

        // add auctionData to token
        await this.loadInvAuctionData();
      } catch (e) {
        console.error('Error loading Inverted Auctions', {
          invAuctions: this.invAuctions,
          user: this.accounts,
          error: e
        });
      }
    },
    loadInvAuctionData: async function () {
      if (!this.nfts.market) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.nfts.market.tokens) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.invAuctions) {
        console.warn('No Inv Auctions; nothing to query', this.invAuctions);
        return;
      }

      for (let i = 0; i < this.nfts.market.tokens.length; i++) {
        let id = this.nfts.market.tokens[i].id;
        // if offer for nft exists, add offer data to token
        if (this.invAuctionTokenIds.includes(id)) {
          let invAuctionData = this.invAuctions.find(a => a.token_id === id);
          this.nfts.market.tokens[i].invAuctionData = invAuctionData;
        }
      }
    },
    loadNftData: async function () {
      if (!this.nfts.market) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      } else if (!this.nfts.market.tokens) {
        console.warn('No NFTs; nothing to query', this.nfts.market);
        return;
      }

      console.log("LOADING NFT DATA");
      for (let i = 0; i < this.nfts.market.tokens.length; i++) {
        let id = this.nfts.market.tokens[i];
        let data = await this.getTokenMeta(id);
        let resolvedMetadata = data;
        resolvedMetadata.id = id;
        this.nfts.market.tokens[i] = resolvedMetadata;
      }

    },
    getTokenMeta: async function (tokenId = false) {
      if (!tokenId || typeof tokenId !== 'string') {
        console.warn('Invalid token ID. Token ID must be a string, but got ' + typeof tokenId);
        return;
      }

      let entrypoint = {
        nft_info: {
          token_id: tokenId
        }
      };

      this.loading = {
        status: true,
        msg: "Loading NFT data of token " + tokenId + "..."
      };

      let query = await this.handlers.query(this.contract, entrypoint);

      // Resolve IPFS metadata
      const httpClient = axios.create();
      let ipfsEndpoint = query['token_uri'];
      let httpEndpoint = ipfsEndpoint.replace('ipfs://', this.ipfs.ipfsGateway);
      let result = await httpClient.get(httpEndpoint);

      // DEBUG (axios res.):
      // console.log('Axios res.', result);

      if (result.data) {
        if (result.data.image) {
          result.data.image = result.data.image.replace('ipfs://', this.ipfs.ipfsGateway);
        }
      }

      entrypoint = {
        owner_of: {
          token_id: tokenId
        }
      }

      let ownerQuery = await this.handlers.query(this.contract, entrypoint);
      if (ownerQuery['owner']) {
        result.data.owner = ownerQuery.owner;
      }
      if (ownerQuery['approvals']) {
        result.data.approvals = ownerQuery.approvals;
      }

      // Graceful exit
      this.loading.status = false;
      this.loading.msg = "";
      return (result.data) ? result.data : query;
    },
    /**
     * Load NFTs of entire marketplace
     * @see {SigningCosmWasmClient}
     * @see https://github.com/archway-network/archway-templates/blob/feature/cosmwasm-sdt-1.0.0-beta5/cw721/off-chain-metadata/src/query.rs#L107-L124
     */
    getNfts: async function () {
      let entrypoint = {
        all_tokens: {}
      };
      this.loading = {
        status: true,
        msg: "Loading NFTs..."
      };
      let query = await this.handlers.query(this.contract, entrypoint);
      console.log('Market NFTs', query);
      this.loading.status = false;
      this.loading.msg = "";
      return query;
    },

    /**
     * Load Offers of entire marketplace
     */
    getOffers: async function () {
      let entrypoint = {
        get_offerings: {}
      };
      this.loading = {
        status: true,
        msg: "Loading all Offers of contract " + this.mpcontract + "..."
      };
      console.log('Starting to load offers of contract: ' + this.mpcontract);
      console.log('With entrypoint: ' + entrypoint);
      let query = await this.handlers.query(this.mpcontract, entrypoint);
      this.loading.status = false;
      this.loading.msg = "";
      return query;
    },

    /**
     * Load auctions of entire marketplace
     */
    getAuctions: async function () {
      let entrypoint = {
        get_auctions: {}
      };
      this.loading = {
        status: true,
        msg: "Loading all auctions of contract " + this.mpcontract + "..."
      };
      let query = await this.handlers.query(this.mpcontract, entrypoint);
      this.loading.status = false;
      this.loading.msg = "";
      return query;
    },

    /**
     * Load inverted auctions of entire marketplace
     */
    getInvertedAuctions: async function () {
      let entrypoint = {
        get_inverted_auctions: {}
      };
      this.loading = {
        status: true,
        msg: "Loading all inverted auctions of contract " + this.mpcontract + "..."
      };
      let query = await this.handlers.query(this.mpcontract, entrypoint);
      this.loading.status = false;
      this.loading.msg = "";
      return query;
    },


    /**
     * Mint an NFT
     * @see {SigningCosmWasmClient}
     * @see https://github.com/drewstaylor/archway-template/blob/main/src/contract.rs#L42
     */
    mintNft: async function () {
      // SigningCosmWasmClient.execute: async (senderAddress, contractAddress, msg, fee, memo = "", funds)
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      }

      // Refresh NFT market to get last minted ID
      // (Tx. might still fail if multiple users try to mint in the same block)
      this.loadNfts();
      // console.log('this.nfts.market', this.nfts.market);

      // Prepare Tx
      let entrypoint = {
        mint: {
          token_id: String(this.nfts.market.tokens.length),
          owner: this.accounts[0].address,
          token_uri: this.metadata.uri,
          extension: null, // XXX: null prop?
        }
      };

      console.log('Entrypoint', entrypoint);

      this.loading = {
        status: true,
        msg: "Minting NFT..."
      };
      // XXX TODO: Fix gas estimation? (https://github.com/cosmos/cosmjs/issues/828)
      console.log('Tx args', {
        senderAddress: this.accounts[0].address,
        contractAddress: this.contract,
      });
      let txFee = calculateFee(300000, this.gas.price);
      try {
        // Send Tx
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.contract, entrypoint, txFee);
        this.loading.status = false;
        this.loading.msg = "";
        console.log('Mint Tx', tx);

        // Reset minting form
        this.resetMetadataForm();

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              mint: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh NFT collections (all NFTs and NFTs owned by end user)
        await this.loadNfts();
        if (this.accounts.length) {
          await this.getBalances();
        }
      } catch (e) {
        console.warn('Error executing mint tx', e);
        this.loading.status = false;
        this.loading.msg = "";
      }
    },
    getBalances: async function () {
      if (!this.chainMeta) {
        return;
      } else if (!this.chainMeta['chainName']) {
        return;
      } else if (!this.chainMeta['currencies']) {
        return;
      } else if (!this.chainMeta.currencies.length) {
        return;
      }
      this.loading = {
        status: true,
        msg: "Updating account balances..."
      };
      if (this.accounts) {
        if (this.accounts.length) {
          for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i]['address']) {
              try {
                console.log('address', this.accounts[i].address);
                let balance = await this.wasmClient.getBalance(this.accounts[i].address, this.chainMeta.currencies[0].coinMinimalDenom);
                this.accounts[i].balance = balance;
                this.loading.status = false;
                this.loading.msg = "";
                console.log('Balance updated', this.accounts[i].balance);
              } catch (e) {
                console.warn('Error reading account address', [String(e), this.accounts[i]]);
                this.loading.status = false;
                this.loading.msg = "";
                return;
              }
            } else {
              console.warn('Failed to resolve account address at index ' + i, this.accounts[i]);
            }
          }
        } else {
          this.loading.status = false;
          this.loading.msg = "";
          console.warn('Failed to resolve Keplr wallet');
        }
      } else {
        this.loading.status = false;
        this.loading.msg = "";
        console.warn('Failed to resolve Keplr wallet');
      }
    },
    handleTransfer: async function () {
      if (!this.selectedTokenId || !this.transferring.recipient || !this.transferring.selected || this.isSending) {
        console.warn('Nothing to transfer (check token ID and recipient address)', this.transferring);
        return;
      }
      await this.transferNft(this.transferring.recipient, this.selectedTokenId);
    },
    /**
     * Transfer an NFT to another user
     * @see {SigningCosmWasmClient}
     * @param {String} recipient : A recipient contract or wallet address
     * @param {String} tokenId : ID of the token to transferred to `recipient`
     * @see https://github.com/archway-network/archway-templates/blob/feature/cosmwasm-sdt-1.0.0-beta5/cw721/off-chain-metadata/src/msg.rs#L62-L74
     */
    transferNft: async function (recipient = null, tokenId = null) {
      // SigningCosmWasmClient.execute: async (senderAddress, contractAddress, msg, fee, memo = "", funds)
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!tokenId || !recipient) {
        console.warn('Nothing to transfer (check token ID and recipient address)', {
          token_id: tokenId,
          recipient: recipient
        });
        return;
      }
      // Prepare Tx
      let entrypoint = {
        transfer_nft: {
          recipient: recipient,
          token_id: tokenId
        }
      };
      this.isSending = true;
      this.loading = {
        status: true,
        msg: "Transferring NFT to " + recipient + "..."
      };
      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.contract, entrypoint, txFee);
        console.log('Transfer Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isSending = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              transfer: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh NFT collections and balances
        await this.loadNfts();
        if (this.accounts.length) {
          await this.getBalances();
        }
      } catch (e) {
        console.warn("Error executing NFT transfer", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isSending = false;
      }
    },
    handleOfferNft: async function () {
      if (!this.selectedTokenId || !this.createOffer.price || !this.createOffer.selected || this.isCreatingOffer) {
        console.warn('Not enough data to create offer! (check params)', this.createOffer);
        return;
      }
      let offerPrice = this.createOffer.price * 1000000;
      await this.offerNft(offerPrice, this.selectedTokenId);
    },
    /**
     * Create an Offer for an NFT
     * @param {number} offer_price : price for the offer
     * @param {String} token_id : token id of the NFT
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    offerNft: async function (offer_price = null, token_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!token_id || !offer_price) {
        console.warn('Something is missing, check params!', {
          token_id: token_id,
          offer_price: offer_price,
        });
        return;
      }

      this.isCreatingOffer = true;

      const msg = `{"list_price":{"address":"${this.mpcontract}","amount":"${offer_price}"}}`
      const encodedMsg = Buffer.from(msg).toString("base64")

      let entrypoint = {
        offer_nft: {
          sender: this.accounts[0].address,
          token_id: token_id,
          msg: encodedMsg,
        }
      };

      this.loading = {
        status: true,
        msg: "Creating offer for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Offer Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingOffer = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              offer: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh offers
        await this.loadOffers();
      } catch (e) {
        console.warn("Error offering NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingOffer = false;
      }
    },
    handleAcceptOffer: async function (offering_id = null, list_price = null) {
      if (!offering_id || !list_price || this.isAcceptingOffer) {
        console.warn('Not enough data to accept offer! (check params: ' + offering_id, list_price, this.isAcceptingOffer + ')');
        return;
      }
      await this.acceptOffer(offering_id, list_price);
    },
    /**
     * Accept an offer for an NFT
     * @param {String} offering_id : id of the offering
     * @param {Uint128} nft_price : price of the NFT
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    acceptOffer: async function (offering_id = null, nft_price = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!offering_id || !nft_price) {
        console.warn('Something is missing, check params!', {
          offering_id: offering_id,
          nft_price: nft_price,
        });
        return;
      }

      this.isAcceptingOffer = true;

      const msg = `{"offering_id":"${offering_id}"}`;
      const encodedMsg = Buffer.from(msg).toString("base64");

      let entrypoint = {
        accept_offered_nft: {
          sender: this.accounts[0].address,
          amount: nft_price,
          msg: encodedMsg,
        },
      };

      this.loading = {
        status: true,
        msg: "Creating offer for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Accept Offer Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isAcceptingOffer = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              offer: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh offers
        await this.loadOffers();
      } catch (e) {
        console.warn("Error acceptig offered NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isAcceptingOffer = false;
      }
    },
    handleAuctionNft: async function () {
      if (!this.selectedTokenId || !this.createAuction.min_bid || !this.createAuction.start_time || !this.createAuction.deadline || !this.createAuction.selected || this.isCreatingAuction) {
        console.warn('Not enough data to create auction! (check params)', this.createAuction);
        return;
      }
      let min_bid = this.createAuction.min_bid * 1000000;
      let max_bid;
      if (this.createAuction.max_bid) {
        max_bid = this.createAuction.max_bid * 1000000;
      }
      let start_time = moment(new Date(this.createAuction.start_time)).utc().valueOf() * 1000000;
      let deadline = moment(new Date(this.createAuction.deadline)).utc().valueOf() * 1000000;

      await this.auctionNft(this.selectedTokenId, min_bid, max_bid, "CONST", start_time, deadline);
    },
    /**
     * Create an Auction for an NFT
     * @param {number} min_bid : minimal bid for the auction
     * @param {number} max_bid : maximal bid for the auction
     * @param {String} token_id : token id of the auctioned NFT
     * @param {string} accepted_token_id : token that the NFT is for sale
     * @param {Timestamp} start_time : start time of the auction
     * @param {Timestamp} deadline : deadline of the auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    auctionNft: async function (token_id = null, min_bid = null, max_bid = null, accepted_token_id = null, start_time = null, deadline = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!min_bid || !accepted_token_id || !start_time || !deadline || !token_id) {
        console.warn('Something is missing, check params!', {
          min_bid: min_bid,
          accepted_token_id: accepted_token_id,
          start_time: start_time,
          deadline: deadline,
          token_id: token_id
        });
        return;
      }

      this.isCreatingAuction = true;


      let msg;
      if (max_bid) {
        msg = `{"min_bid":"${min_bid}","max_bid":"${max_bid}","accepted_token_id":"${accepted_token_id}","start_time":"${start_time}","deadline":"${deadline}"}`;
      } else {
        msg = `{"min_bid":"${min_bid}","accepted_token_id":"${accepted_token_id}","start_time":"${start_time}","deadline":"${deadline}"}`;
      }

      const encodedMsg = Buffer.from(msg).toString("base64")

      let entrypoint = {
        auction_nft: {
          sender: this.accounts[0].address,
          token_id: token_id,
          msg: encodedMsg,
        }
      };

      this.loading = {
        status: true,
        msg: "Creating auction for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingAuction = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh auctions
        await this.loadAuctions();
      } catch (e) {
        console.warn("Error auctioning NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingAuction = false;
      }
    },
    handleBidOnAuction: async function () {
      if (!this.selectedAuctionTokenId || !this.bidOnAnAuction.auction_id || !this.bidOnAnAuction.bid_amount || this.isBiddingOnAuction) {
        console.warn('Not enough data to bid on auction! (check params)', this.bidOnAnAuction);
        return;
      }
      let bid_amount = this.bidOnAnAuction.bid_amount * 1000000;
      await this.bidOnAuction(this.bidOnAnAuction.auction_id, "CONST", bid_amount);
    },
    /**
     * Bid on an Auction for an NFT
     * @param {String} auction_id : the id of the auction
     * @param {String} auctioned_token_id : token id of the bid amount
     * @param {number} amount : the amount of the bid
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    bidOnAuction: async function (auction_id = null, auctioned_token_id = null, amount = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!auction_id || !amount || !auctioned_token_id) {
        console.warn('Something is missing, check params!', {
          auction_id: auction_id,
          token_id: auctioned_token_id,
          amount: amount
        });
        return;
      }

      const msg = `{"auction_id":"${auction_id}","token_id":"${auctioned_token_id}"}`
      const encodedMsg = Buffer.from(msg).toString("base64")

      this.isBiddingOnAuction = true;

      let entrypoint = {
        bid_on_auction: {
          sender: this.accounts[0].address,
          amount: amount.toString(),
          msg: encodedMsg,
        }
      };

      this.loading = {
        status: true,
        msg: "Bid on an auction..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Bid on Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isBiddingOnAuction = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              bid_on_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh auctions
        await this.loadAuctions();
      } catch (e) {
        console.warn("Error bidding on an Auction", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isBiddingOnAuction = false;
      }
    }
    ,
    handleInvAuctionNft: async function () {
      if (!this.selectedTokenId || !this.createInvAuction.start_amount || !this.createInvAuction.reduce_amount || !this.createInvAuction.end_amount || !this.createInvAuction.start_time || !this.createInvAuction.deadline || !this.createInvAuction.selected || this.isCreatingInvAuction) {
        console.warn('Not enough data to create inverted auction! (check params)', this.createInvAuction);
        return;
      }
      await this.invertedAuctionNft(this.createInvAuction.start_amount, this.createInvAuction.reduce_amount, this.createInvAuction.end_amount, this.selectedTokenId, "CONST", this.createInvAuction.start_time, this.createInvAuction.deadline);
    },
    /**
     * Create an inverted Auction for an NFT
     * @param {Uint128} start_amount : start amount of the inverted auction
     * @param {Uint128} reduce_amount : reduce amount of the inverted auction
     * @param {Uint128} end_amount : end amount of the inverted auction
     * @param {String} token_id : token id of the auctioned NFT
     * @param {String} accepted_token_id : token that the NFT is for sale
     * @param {Timestamp} start_time : start time of the auction
     * @param {Timestamp} deadline : deadline of the auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    invertedAuctionNft: async function (start_amount = null, reduce_amount = null, end_amount = null, token_id = null, accepted_token_id = null, start_time = null, deadline = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!start_amount || !reduce_amount || !end_amount || !token_id || !accepted_token_id || !start_time || !deadline) {
        console.warn('Something is missing, check params!', {
          start_amount: start_amount,
          reduce_amount: reduce_amount,
          end_amount: end_amount,
          token_id: token_id,
          accepted_token_id: accepted_token_id,
          start_time: start_time,
          deadline: deadline
        });
        return;
      }

      this.isCreatingInvAuction = true;

      const msg = `{"start_amount":"${start_amount}","reduce_amount":"${reduce_amount}","end_amount":"${end_amount}","accepted_token_id":"${accepted_token_id}","start_time":"${start_time}","deadline":"${deadline}"}`
      const encodedMsg = Buffer.from(msg).toString("base64")

      let entrypoint = {
        inverted_auction_nft: {
          contract: this.mpcontract,
          token_id: token_id,
          msg: encodedMsg,
        }
      };

      this.loading = {
        status: true,
        msg: "Creating auction for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Inverted Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingInvAuction = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              inverted_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh inverted auctions
        await this.loadInvertedAuctions();
      } catch (e) {
        console.warn("Error inverted auctioning NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isCreatingInvAuction = false;
      }
    },
    /**
     * Accept an inverted Auction
     * @param {String} inverted_auction_id : the id of the inverted auction
     * @param {String} accept_token_id : token id of the sent token
     * @param {Uint128} amount : amount to accept inverted auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    acceptInvertedAuction: async function (inverted_auction_id = null, accept_token_id = null, amount = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!inverted_auction_id || !accept_token_id || !amount) {
        console.warn('Something is missing, check params!', {
          inverted_auction_id: inverted_auction_id,
          accept_token_id: accept_token_id,
          amount: amount,
        });
        return;
      }

      const msg = `{"inverted_auction_id":"${inverted_auction_id}","token_id":"${accept_token_id}"}`
      const encodedMsg = Buffer.from(msg).toString("base64")

      let entrypoint = {
        accept_inverted_auction: {
          sender: this.accounts[0].address,
          amount: accept_token_id,
          msg: encodedMsg,
        }
      };

      this.loading = {
        status: true,
        msg: "Accepting inverted auction for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Accept inverted Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              accept_inverted_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh inverted auctions
        await this.loadInvertedAuctions();
      } catch (e) {
        console.warn("Error accepting inverted auction", e);
        this.loading.status = false;
        this.loading.msg = "";
      }
    },
    /**
     * Decrease the value of an inverted Auction for an NFT
     * @param {String} inverted_auction_id : the id of the inverted auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    decreaseInvertedAuctionCurrentValue: async function (inverted_auction_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!inverted_auction_id) {
        console.warn('Something is missing, check params!', {
          inverted_auction_id: inverted_auction_id,
        });
        return;
      }

      let entrypoint = {
        decrease_inverted_auction_current_value: {
          inverted_auction_id: inverted_auction_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Creating auction for NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Decrease current value of inverted Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              decrease_inverted_auction_current_value: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh inverted auctions
        await this.loadInvertedAuctions();
      } catch (e) {
        console.warn("Error decreasing current value of inverted Auction", e);
        this.loading.status = false;
        this.loading.msg = "";
      }
    },
    handleWithdrawInvertedAuction: async function (inv_auction_id = null) {
      if (!inv_auction_id || this.isWithdrawingInvAuction) {
        console.warn('Not enough data to withdraw inverted auction! (check params)');
        return;
      }
      await this.withdrawInvertedAuction(inv_auction_id);
    },
    /**
     * Withdraw an inverted Auction for an NFT
     * @param {String} inverted_auction_id : the id of the inverted auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    withdrawInvertedAuction: async function (inverted_auction_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!inverted_auction_id) {
        console.warn('Something is missing, check params!', {
          inverted_auction_id: inverted_auction_id
        });
        return;
      }

      this.isWithdrawingInvAuction = true;

      let entrypoint = {
        withdraw_inverted_auctioned_nft: {
          inverted_auction_id: inverted_auction_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Withdrawing inverted auctioned NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Withdrawing Inverted Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingInvAuction = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              withdraw_inverted_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh inverted auctions
        await this.loadInvertedAuctions();
      } catch (e) {
        console.warn("Error withdraw inverted auctioned NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingInvAuction = false;
      }
    },
    /**
     * End an inverted Auction for an NFT
     * @param {String} inverted_auction_id : the id of the inverted auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    endInvertedAuction: async function (inverted_auction_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!inverted_auction_id) {
        console.warn('Something is missing, check params!', {
          inverted_auction_id: inverted_auction_id
        });
        return;
      }

      let entrypoint = {
        end_inverted_auction: {
          inverted_auction_id: inverted_auction_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Ending inverted auction..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Ending Inverted Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              end_inverted_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh inverted auctions
        await this.loadInvertedAuctions();
      } catch (e) {
        console.warn("Error ending inverted auction", e);
        this.loading.status = false;
        this.loading.msg = "";
      }
    },
    handleWithdrawAuction: async function (auction_id = null) {
      if (!auction_id || this.isWithdrawingAuction) {
        console.warn('Not enough data to withdraw auction! (check params)');
        return;
      }
      await this.withdrawAuction(auction_id);
      // Load Auctions
      await this.loadAuctions();
    },
    /**
     * Withdraw an Auction for an NFT
     * @param {String} auction_id : the id of the auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    withdrawAuction: async function (auction_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!auction_id) {
        console.warn('Something is missing, check params!', {
          auction_id: auction_id
        });
        return;
      }

      this.isWithdrawingAuction = true;

      let entrypoint = {
        withdraw_auctioned_nft: {
          auction_id: auction_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Withdrawing auctioned NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Withdrawing Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingAuction = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              withdraw_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh auctions
        await this.loadAuctions();
      } catch (e) {
        console.warn("Error withdraw auctioned NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingAuction = false;
      }
    },
    handleEndAuction: async function (auction_id = null) {
      if (!auction_id || this.isEndingAuction) {
        console.warn('Not enough data to end auction! (check params)');
        return;
      }
      await this.endAuction(auction_id);
      // Load Auctions
      await this.loadAuctions();
    },
    /**
     * End an Auction for an NFT
     * @param {String} auction_id : the id of the auction
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    endAuction: async function (auction_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!auction_id) {
        console.warn('Something is missing, check params!', {
          auction_id: auction_id
        });
        return;
      }

      let entrypoint = {
        end_auction: {
          auction_id: auction_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Withdrawing auctioned NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Ending Auction Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              end_auction: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh auctions
        await this.loadAuctions();
      } catch (e) {
        console.warn("Error ending auction", e);
        this.loading.status = false;
        this.loading.msg = "";
      }
    },
    handleWithdrawOffer: async function (offering_id = null) {
      if (!offering_id || this.isWithdrawingOffer) {
        console.warn('Not enough data to withdraw offer! (check params:' + offering_id + ', ' + this.isWithdrawingOffer + ')');
        return;
      }
      await this.withdrawOffer(offering_id);
      // Load Offers
      await this.loadOffers();
    },
    /**
     * Withdraw an Offer for an NFT
     * @param {String} offer_id : the id of the offer
     * @see https://github.com/kelepar/nft-marketplace-contract
     */
    withdrawOffer: async function (offer_id = null) {
      if (!this.accounts) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!this.accounts.length) {
        console.warn('Error getting user', this.accounts);
        return;
      } else if (!offer_id) {
        console.warn('Something is missing, check params!', {
          offer_id: offer_id
        });
        return;
      }

      this.isWithdrawingOffer = true;

      let entrypoint = {
        withdraw_offered_nft: {
          offering_id: offer_id,
        }
      };

      this.loading = {
        status: true,
        msg: "Withdrawing offered NFT..."
      };

      // Send Tx
      let txFee = calculateFee(300000, this.gas.price);
      try {
        let tx = await this.wasmClient.execute(this.accounts[0].address, this.mpcontract, entrypoint, txFee);
        console.log('Withdrawing Offer Tx', tx);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingOffer = false;

        // Update Logs
        if (tx.logs) {
          if (tx.logs.length) {
            this.logs.unshift({
              withdraw_offer: tx.logs,
              timestamp: new Date().getTime()
            });
            console.log('Logs Updated', this.logs);
          }
        }
        // Refresh offers
        await this.loadOffers();
      } catch (e) {
        console.warn("Error withdraw offered NFT", e);
        this.loading.status = false;
        this.loading.msg = "";
        this.isWithdrawingOffer = false;
      }
    },
    initMethod: async function () {
      if (this.wasmClient == null) {
        console.log('Init Query...');
        if (window) {
          if (window['keplr']) {
            if (window.keplr['experimentalSuggestChain']) {
              this.isInitialLoading = true;
              this.offlineSigner = await window.getOfflineSigner(this.chainMeta.chainId);
              this.wasmClient = await SigningCosmWasmClient.connectWithSigner(this.rpc, this.offlineSigner);
              // Query ref.
              this.handlers.query = this.wasmClient.queryClient.wasm.queryContractSmart;

              // User and market NFTs
              await this.loadNfts();

              // Load Offers
              await this.loadOffers();

              // Load Auctions
              await this.loadAuctions();

              // Load Inverted Auctions
              await this.loadInvertedAuctions();
              this.isInitialLoading = false;
            }
          }
        }
      }
    },
  },
  computed: {
    myNfts: function () {
      if (!this.nfts.market) {
        console.log("no nfts.market");
        return [];
      } else if (!this.nfts.market.tokens) {
        console.log("no nfts.market.tokens");
        return [];
      } else if (!this.nfts.market.tokens.length) {
        console.log("no nfts.market.tokens.length");
        return [];
      }

      return this.nfts.market.tokens.filter((token) => {
        if (token.owner) {
          if (token.owner === this.accounts[0].address)
            return token;
        }
      });
    },

    offeredNfts: function () {
      if (!this.nfts.market) {
        console.log("no nfts.market");
        return [];
      } else if (!this.nfts.market.tokens) {
        console.log("no nfts.market.tokens");
        return [];
      } else if (!this.nfts.market.tokens.length) {
        console.log("no nfts.market.tokens.length");
        return [];
      }

      return this.nfts.market.tokens.filter((token) => {
        if (this.offersTokenIds.includes(token.id))
          return token;
      });
    },
    auctionedNfts: function () {
      if (!this.nfts.market) {
        console.log("no nfts.market");
        return [];
      } else if (!this.nfts.market.tokens) {
        console.log("no nfts.market.tokens");
        return [];
      } else if (!this.nfts.market.tokens.length) {
        console.log("no nfts.market.tokens.length");
        return [];
      }
      return this.nfts.market.tokens.filter((token) => {
        if (this.auctionTokenIds.includes(token.id))
          return token;
      });
    },
    invAuctionedNfts: function () {
      if (!this.nfts.market) {
        console.log("no nfts.market");
        return [];
      } else if (!this.nfts.market.tokens) {
        console.log("no nfts.market.tokens");
        return [];
      } else if (!this.nfts.market.tokens.length) {
        console.log("no nfts.market.tokens.length");
        return [];
      }

      return this.nfts.market.tokens.filter((token) => {
        if (this.invAuctionTokenIds.includes(token.id))
          return token;
      });
    },
    chunkedNfts() {
      if (this.myNfts.length > 0) {
        return chunk(this.myNfts, 4)
      }
      return [];
    },
    chunkedOfferedNfts() {
      if (this.offeredNfts.length > 0) {
        return chunk(this.offeredNfts, 4)
      }
      return [];
    },
    chunkedAuctionedNfts() {
      if (this.auctionedNfts.length > 0) {
        return chunk(this.auctionedNfts, 4)
      }
      return [];
    },
    chunkedInvAuctionedNfts() {
      if (this.invAuctionedNfts.length > 0) {
        return chunk(this.invAuctionedNfts, 4)
      }
      return [];
    },
    isLoading() {
      return this.isInitialLoading ? this.isInitialLoading : this.loading.status;
    }
  }
}
</script>

<style>
div.logs {
  margin-top: 4em;
}

a.mint-now {
  text-decoration: underline !important;
  cursor: pointer;
}

div.minting-form div {
  margin-top: 0.75em;
}

.dropzone {
  border-radius: 1.5rem;
  padding: 2em;
  text-align: center;
}

.dropzone.ok {
  border: 2px dotted #ffffff;
  background: #3c0150;
  color: #ffffff;
}

.dropzone.waiting {
  border: 2px dotted #e0e0e0;
}

.dropzone.hovering {
  background: #e0e0e0;
  border-color: #000000;
  color: #000000;
}

.dropzone > label {
  margin-top: 1em;
}

[v-cloak] {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.files-list-ul {
  list-style: none;
}

.hidden {
  display: none;
}

.card {
  min-width: 420px;
  max-width: 420px;
  margin: 15px !important;
}

.card-img-top {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.card-img-top:hover {
  position: absolute;
  top: 5%;
  transform: scale(2.5);
  z-index: 9999;
}

.btn,
.btn-send,
.btn-cancel {
  margin: 0 auto;
}

ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0 !important;
  list-style-type: none !important;
}

label.recipient {
  margin-top: 1rem;
}
</style>
